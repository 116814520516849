var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.documents.data.length && _vm.$regions.isBrazil()
    ? _c(
        "b-card",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.list-modal",
              modifiers: { "list-modal": true },
            },
          ],
          staticClass: "text-center text-white",
          staticStyle: { height: "100%" },
          attrs: { "bg-variant": _vm.bgRange, "body-class": "flex" },
        },
        [
          _c("div", [
            _c(
              "p",
              { staticClass: "h1 mb-0 driver-behaviour-title full-width" },
              [_vm._v(" " + _vm._s(_vm.documents.data.length) + " ")]
            ),
            _c(
              "p",
              {
                staticClass:
                  "text-uppercase font-weight-bold text-center full-width mb-0",
              },
              [_vm._v(" CNHs ENVIADAS PELO MOTORISTA ")]
            ),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                id: "list-modal",
                size: "xl",
                title:
                  "Confira abaixo as informações das CNHs enviadas pelo(a) motorista via CM ou via link único",
                "hide-footer": "",
                fields: _vm.fields,
              },
            },
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  striped: "",
                  hover: "",
                  items: _vm.document,
                  fields: _vm.fields,
                  responsive: "sm",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(createdAt)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.formatDate(data.item.createdAt))
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(origin)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c(
                              "b",
                              {
                                class: data.item.origin.includes("public")
                                  ? "text-info"
                                  : "text-danger",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getOrigin(data.item.origin)) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "b-badge",
                                {
                                  attrs: {
                                    variant: _vm.getStatus(data.item).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getStatus(data.item).text) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(linkS3)",
                      fn: function (data) {
                        return [
                          data.item.linkS3
                            ? _c(
                                "div",
                                { staticClass: "d-flex justify-content-end" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        title: "Visualizar Documento",
                                        variant: "info",
                                        size: "sm",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLink(data.item.linkS3)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-eye" })]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        title: "Copiar Link",
                                        variant: "outline-primary",
                                        size: "sm",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(data.item.linkS3)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-copy" })]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "d-flex justify-content-end" },
                                [_vm._v(" N/A ")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3804440232
                ),
              }),
              _vm.findGroups("ACL:DRIVER_DOCUMENT:ADD_STATUS")
                ? [
                    _c(
                      "div",
                      { staticClass: "custom-modal-footer" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.loading,
                              variant: "primary",
                            },
                            on: { click: _vm.newShipment },
                          },
                          [_vm._v(" Solicitar novo envio ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }