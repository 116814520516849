<template>
  <div v-if="showLockAlert" class="alert alert-danger mb-2" role="alert">
    <h4 class="alert-heading" v-text="headingText" />
    <hr>
    <p>{{ lockText }}</p>
    <p v-if="driver_promise_profile" v-html="getAlertText" /> 
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import DRIVER_PROMISE_PROFILE from '@graphql/transaction/queries/driverPromiseProfile.gql';
export default {
  name: 'DriverLockStatus',
  props: {
    driver: {
      type: Object,
      required: true,
      default: () => ({
        is_overdue: false,
        booking: {
          id: ''
        },
        last_fleet_operation: {
          booking: {
            id: ''
          },
          status: '',
          created_at: '',
          lock_reason: '',
          lock_request_by: '',
          payment_promise: {
            created_at: '',
            promise_date: '',
            status: '',
            transaction: ''
          }
        }
      }),
    }
  },
  data: () => {
    return {
      kovi_fatura: process.env.KOVI_FATURA_URL,
    };
  },
  apollo: {
    driver_promise_profile: {
      query: DRIVER_PROMISE_PROFILE,
      variables() {
        return {
          driver: this.$route.params.id,
        };
      },
      update: data => data.getPromiseProfileByDriver,
      skip() {
        return this.driver.last_fleet_operation == null;
      },
    },
  },
  computed: {
    showLockAlert() {
      return (this.driverLockedByActiveBooking && this.driverIsLocked) || this.driverInPromise;
    },
    driverIsLocked() {
      return this.driver.last_fleet_operation?.status === 'LOCK_PENDING' || this.driver.last_fleet_operation?.status === 'LOCKED'
    },
    driverIsLockedByPaymentPromise() {
      return (this.driver.last_fleet_operation?.status === 'LOCK_PENDING' 
        || this.driver.last_fleet_operation?.status === 'LOCKED')
        && this.driver.last_fleet_operation?.lock_request_by?.includes('autogenerated@payment-promise');
    },
    driverLockedByActiveBooking() {
      return this.driver?.booking?.id === this.driver.last_fleet_operation?.booking?.id
    },
    driverInPromise() {
      return this.driver_promise_profile?.promiseStatus === 'OPEN'
    },
    promiseIsFulfilled() {
      if (
        this.driver_promise_profile?.invoices &&
        this.driver_promise_profile?.invoices.every((invoice) => invoice.status === 'PAID' || invoice.status === 'CANCELED')
        ) return true
      return false
    },
    driverIsEligibleForPromise() {
      return this.driver_promise_profile?.isEligible
    },
    driverIsLockedByOverdue(){
      const overdueReasons = ['OVERDUE', 'OVERDUE_EMERGENCY']
      return overdueReasons.includes(this.driver.last_fleet_operation?.lock_reason)
    },
    headingText() {
      if (this.driverIsLocked && this.driverIsLockedByOverdue) return this.$t('drivers.lockStatus.overdueReason');
      if (!this.driverIsLocked && this.driverInPromise) return this.$t('drivers.lockStatus.unlockedByPromise');
      const lockReason = this.$t(`fleetProfile.lockReasons.${this.driver.last_fleet_operation.lock_reason}`);
      return `${this.$t('drivers.lockStatus.otherReason')}: ${lockReason}`;
    },
    lockText(){
      return `${this.$t('drivers.lockStatus.alertMessage')}: 
        ${ this.$moment(this.driver.last_fleet_operation.created_at).format('DD/MM/YYYY HH:mm:ss')  || this.$moment('DD/MM/YYYY HH:mm:ss')}`
    },
    getAlertText() {
      if (this.driverIsLockedByPaymentPromise) return this.$t('drivers.lockStatus.lockedByPaymentPromise');
      if (this.driverIsLocked && !this.driverIsLockedByOverdue) return this.$t('drivers.lockStatus.lockedAndNotEligibleByLockedType');
      if (!this.driverIsLocked && this.driverInPromise && this.promiseIsFulfilled) 
        return `${this.$t('drivers.lockStatus.unlockedByFulfilledPromise')} <br> <br> ${this.getEligibleOrPromiseInvoicesText}`;
      if (!this.driverIsLocked && this.driverInPromise) return `${this.$t('drivers.lockStatus.unlockedByPromiseDetailed')} <br> <br> ${this.getEligibleOrPromiseInvoicesText}`;
      if (this.driverIsLocked && !this.driverIsEligibleForPromise)
        return this.$t('drivers.lockStatus.lockedAndNotEligible') + this.$t(`payments.promiseNotEligible.${this.driver_promise_profile?.reason}`);
      if (this.driverIsLocked && this.driverIsEligibleForPromise) return `${this.$t('drivers.lockStatus.lockedAndEligible')} <br> <br> ${this.getEligibleOrPromiseInvoicesText}`;
      return this.$t('drivers.lockStatus.noAlertFound');
    },
    getEligibleOrPromiseInvoicesText() {
      return this.driver_promise_profile?.invoices?.map((invoice) => {
          return `<a href="${this.kovi_fatura}${invoice.transaction}"> ${invoice.description.slice(0, 60)} </a> | 
          <b> ${this.$moment(invoice.originalDueDate).format('DD/MM/YYYY')} </b> | 
          ${formatPrice(this.$t('payments.labels.currency'), invoice.amount)} |
          <b> ${this.$t(`payments.enumStatus.${invoice.status}`)} </b>
        `;

        }).join("<br>");
    },
  }
};  
</script>