<template>
  <b-card
    v-if="documents.data.length && $regions.isBrazil()"
    v-b-modal.list-modal
    :bg-variant="bgRange"
    class="text-center text-white"
    body-class="flex"
    style="height: 100%"
  >
    <div>
      <p class="h1 mb-0 driver-behaviour-title full-width">
        {{ documents.data.length }}
      </p>
      <p class="text-uppercase font-weight-bold text-center full-width mb-0">
        CNHs ENVIADAS PELO MOTORISTA
      </p>
    </div>
    <b-modal id="list-modal" size="xl" title="Confira abaixo as informações das CNHs enviadas pelo(a) motorista via CM ou via link único"
             hide-footer :fields="fields"
    >
      <b-table small striped hover
               :items="document" :fields="fields" responsive="sm"
      >
        <template #cell(createdAt)="data">
          <div>
            <b>{{ formatDate(data.item.createdAt) }}</b>
          </div>
        </template>
        <template #cell(origin)="data">
          <div>
            <b :class="data.item.origin.includes('public') ? 'text-info' : 'text-danger'">
              {{ getOrigin(data.item.origin) }}
            </b>
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge :variant="getStatus(data.item).color">
              {{ getStatus(data.item).text }}
            </b-badge>
          </div>
        </template>
        <template #cell(linkS3)="data">
          <div v-if="data.item.linkS3" class="d-flex justify-content-end">
            <b-button title="Visualizar Documento" variant="info" size="sm"
                      @click="openLink(data.item.linkS3)"
            >
              <i class="fa fa-eye" />
            </b-button>
            &nbsp;
            <b-button title="Copiar Link" variant="outline-primary" size="sm"
                      @click="copy(data.item.linkS3)"
            >
              <i class="fa fa-copy" />
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-end">
            N/A
          </div>
        </template>
      </b-table>
      <template v-if="findGroups('ACL:DRIVER_DOCUMENT:ADD_STATUS')">
        <div class="custom-modal-footer">
          <b-button :disabled="loading" variant="primary" @click="newShipment">
            Solicitar novo envio
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>
<script>

import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import EventBus from '@utils/eventBus';

export default {
  name: 'DocumentSendToDriverCard',
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      link: '',
      documents: { data: [], count: 0},
      fields: [
        { key: 'createdAt', label: 'Data do Envio' },
        { key: 'origin', label: 'Origem', class: 'text-center' },
        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'linkS3', label: 'Link do Documento', class: 'text-right', },
      ]
    }
  },
  computed: {
    ...mapState('driverDocument', ['cnhAutoUpload']),
    ...mapGetters('user', ['attributes', 'findGroups']),
    bgRange() {
      if (['rejected', 'force_update'].includes(this.cnhAutoUpload.status)) {
        return 'danger'
      }

      if (this.cnhAutoUpload.status === 'approved') {
        return 'warning'
      }

      return 'success'
    },
    document() {
      return this.documents.data.map((el) => {
        return {
          createdAt: el.createdAt,
          origin: el.origin,
          status: el.status,
          linkS3: el.linkS3,
          expiredAt: el.providerData.license_expired_at,
        }
      }).sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) return 1
        return -1
      })
    }
  },
  mounted() {
    this.listDriverDocuments()
  },
  methods: {
    listDriverDocuments() {
      this.$fleetRegulationProvider.get(`/documents/list?driverId=${this.driverId}`)
        .then((response) => {
          this.documents = response.data
        })
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
    },
    getOrigin(origin) {
      if (origin === 'publicdrivercentral') return 'público'
      if (origin === 'rental') return 'rental'
      return 'central do motorista'
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    isDateMoreThanAMonthAgo(date) {
      const currentDate = new Date()

      const oneMonthAgo = new Date(currentDate)
      oneMonthAgo.setMonth(currentDate.getMonth() - 1)

      return new Date(date) < oneMonthAgo
    },
    getStatus(data) {
      const result = {
        'pending': { color: 'warning', text: 'Pendente' },
        'pending_approval': { color: 'warning', text: 'Pendente de aprovação' },
        'approved': { color: 'warning', text: 'Aprovado' },
        'send_to_rental': { color: 'success', text: 'Já atualizado no rental' },
        'rejected': { color: 'danger', text: 'Rejeitado' },
        'force_update': { color: 'danger', text: 'Forçar Atualização' },
      }
      if (data.origin === 'rental' && this.isDateMoreThanAMonthAgo(data.expiredAt)) {
        return result.force_update
      }
      return result[data.status]
    },
    copy(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.showSuccessModal('Link Copiado')
      }).catch(err => {
        console.error('Falha ao copiar o texto: ', err);
      });
    },
    newShipment() {
      this.loading = true
      this.$fleetRegulationProvider.put(`/drivers/${this.driverId}/license/invalidate`, {})
        .then(async () => {
          EventBus.$emit('GENERATE_QRCODE');
          this.$bvModal.hide('list-modal');
        })
        .finally(() => {
          this.loading = false
        })
    },
    showSuccessModal(msg, text) {
      this.$swal({
        type: 'success',
        title: msg,
        text: text,
        showConfirmButton: false,
        timer: 15000,
      })
    },
    showErrorModal(msg) {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: msg,
        showConfirmButton: false,
        timer: 15000,
      });
    },
  }
}
</script>
<style scoped>
.flex {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.custom-modal-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white; /* Ensure background covers the modal body content */
  padding: 10px; /* Adjust padding as needed */
  border-top: 1px solid #dee2e6; /* Optional: adds a top border to the footer */
  text-align: right; /* Align the button to the right */
}
.actions-column {
  width: 50px !important; /* Ajuste a largura conforme necessário */
}
</style>
