import { render, staticRenderFns } from "./document-send-to-driver.vue?vue&type=template&id=5451071d&scoped=true&"
import script from "./document-send-to-driver.vue?vue&type=script&lang=js&"
export * from "./document-send-to-driver.vue?vue&type=script&lang=js&"
import style0 from "./document-send-to-driver.vue?vue&type=style&index=0&id=5451071d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5451071d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/seed/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5451071d')) {
      api.createRecord('5451071d', component.options)
    } else {
      api.reload('5451071d', component.options)
    }
    module.hot.accept("./document-send-to-driver.vue?vue&type=template&id=5451071d&scoped=true&", function () {
      api.rerender('5451071d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/drivers/profile/document-send-to-driver.vue"
export default component.exports