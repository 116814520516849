<template>
  <div>
    <div v-if="!$apollo.queries.driver.loading" class="animated fadeIn">
      <driver-lock-status :driver="driver" />
      <b-card-group class="mb-4" deck>
        <recurrency-card-widget
          v-if="driver.booking && driver.booking.recurrency_data "
          :to="'/drivers/profile/' + $route.params.id + '/bookings'"
          :title="$t('recurrency.labels.active_plan')"
          :next-date="recurrency.next_date"
          :amount="recurrency.amount"
          :weekly-pay-day="recurrency.weekly_pay_day"
          :payment-method-type="recurrency.payment_method"
          :value="driver.booking.plan.name"
          icon="fa fa-dollar"
        />
        <card-widget
          v-if="driver_trips && driver_trips.count"
          title="Total de corridas"
          :value="driver_trips.count.toString()"
          icon="fa fa-road"
        />
        <b-card-group>
          <total-fines-card
            :fines="driver.total_fines"
          />
          <document-send-to-driver-card
            title="Abrir Historico de Envios"
            style="cursor: pointer;"
            :driver-id="driver.id"
          />
        </b-card-group>
        <!-- <driver-license-status :driver="driver"/> -->
        <driver-contract-status v-if="driver.booking && groups.includes('SQUAD-FLEET')" :booking="driver.booking" />
      </b-card-group>
      <b-row>
        <b-col sm="12" md="3">
          <b-card v-if="driver.status === 'SIGNUP' && driver.mileageProfile.isLowMileage !== null">
            <div v-if="driver.mileageProfile.isLowMileage" class="mb-0 font-weight-bold card-value text-muted text-uppercase font-weight-bold">
              <i class="fa fa-check text-success" aria-hidden="true" /> <span class="text-success">Driver Baixa KM: Aprovado</span>
            </div>

            <div v-if="!driver.mileageProfile.isLowMileage" class="mb-0 font-weight-bold card-value text-muted text-uppercase font-weight-bold">
              <i class="fa fa-ban text-danger" aria-hidden="true" /> <span class="text-danger">Driver Baixa KM: Reprovado</span>
            </div>
          </b-card>
          <driver-behavior-card />
        </b-col>
        <b-col sm="12" md="3">
          <weekly-traveled
            :driver="driver"
          />
        </b-col>
        <b-col sm="12" md="6">
          <driver-mileage-chart
            :driver="driver"
          />
        </b-col>
      </b-row>
      <b-card-group class="driver-data mb-4">
        <b-card v-if="driver.booking" title="Aluguel">
          <p v-if="driver.booking.car" class="card-text">
            <b-link :to="'/fleet/' + driver.booking.car.id">
              <strong>
                {{ driver.booking.car.brand.name }}
                {{ driver.booking.car.model.name }}
              </strong>
            </b-link>
            <span class="detail">({{ driver.booking.car.license_plate }})</span><br>
            <span class="text-muted">Carro</span>
          </p>
          <p v-else class="card-text">
            <strong>Aguardando...</strong><br><span class="text-muted">Carro</span>
          </p>
          <p class="card-text">
            <strong>{{ driver.booking.started_at | moment('DD/MM/YYYY') }}
              <span v-if="driver.booking.finished_at">a {{
                driver.booking.finished_at | moment('DD/MM/YYYY')
              }}</span></strong>
            <span v-if="!driver.booking.finished_at" class="detail">({{
              $moment(driver.booking.started_at).format('dddd')
            }})</span><br><span class="text-muted">Período</span>
          </p>
          <p class="card-text">
            <strong>{{ driver.booking.plan.name }}</strong>
            <span class="detail">({{ driver.booking.transaction_type }})</span><br><span
              class="text-muted"
            >Plano Ativo</span>
          </p>
          <p class="card-text">
            <strong>{{ setPriceFormat(driver.booking.transaction_amount) }}</strong><br><span
              class="text-muted"
            >Valor do plano</span>
          </p>
        </b-card>
        <b-card v-if="
          driver.emergency_1_phone_number ||
            driver.emergency_2_phone_number ||
            driver.phone_number ||
            driver.email
        " title="Contatos"
        >
          <p class="card-text">
            <b-link :href="'tel:' + driver.phone_number">
              <strong>{{ setPhoneFormat(driver.phone_number) }}</strong>
            </b-link>
            <br><span class="text-muted">Número principal</span>
          </p>
          <p class="card-text">
            <b-link :href="'mailto:' + driver.email">
              <strong>{{ driver.email }}</strong>
            </b-link>
            <br><span class="text-muted">Email</span>
          </p>
          <p v-if="driver.emergency_1_phone_number" class="card-text">
            <b-link :href="'tel:' + driver.emergency_1_phone_number">
              <strong>{{ setPhoneFormat(driver.emergency_1_phone_number) }}</strong>
            </b-link>
            <br><span class="text-muted">{{ driver.emergency_1_name }}</span>
          </p>
          <p v-if="driver.emergency_2_phone_number" class="card-text">
            <b-link :href="'tel:' + driver.emergency_2_phone_number">
              <strong>{{ setPhoneFormat(driver.emergency_2_phone_number) }}</strong>
            </b-link>
            <br><span class="text-muted">{{ driver.emergency_2_name }}</span>
          </p>
        </b-card>
        <b-card v-if="getDriverAddress(driver)" title="Endereço">
          <p class="card-address" v-html="getDriverAddress(driver)" />
          <p class="card-address-search">
            <b-button
              :href="`https://www.google.com/maps/search/${getDriverAddress(driver, true)}`"
              target="_blank"
              size="sm"
              variant="primary"
              title="Abrir no Google Maps"
            >
              <i class="fa fa-map-o" /> Google Maps
            </b-button>
          </p>
          <div v-if="driver.garage_other_address" class="card-text">
            <span class="font-weight-bold">Endereço Garagem </span>
            <p class="card-text">
              {{ getDriverGarageAddress(driver) }}
            </p>
          </div>
        </b-card>
        <b-card
          v-if="$regions.isBrazil()"
          title="Conta Bancária"
        >
          <div v-if="account">
            <p class="card-text">
              <strong>{{ account.bank_name }}</strong>
              <br>
              <span class="text-muted">Banco</span>
            </p>
            <p>
              <strong>{{ account.branch }}</strong>
              <br>
              <span class="text-muted">Agencia</span>
            </p>
            <p>
              <strong>{{ account.number }}</strong>
              <br>
              <span class="text-muted">Conta</span>
            </p>
            <p>
              <strong>{{ setTypeAccount(account.account_type) }}</strong>
              <br>
              <span class="text-muted">Tipo</span>
            </p>
          </div>
          <div
            v-else
          >
            <p>Não há contas cadastradas.</p>
          </div>
          <div>
            <b-btn
              type="button"
              variant="primary"
              size="sm"
              @click="showModal"
            >
              {{ $t('payments.labels.updateBankData') }}
            </b-btn>
          </div>
        </b-card>
      </b-card-group>
    </div>

    <div v-else class="animated fadeIn">
      <content-loading />
    </div>

    <modal-bank-data
      v-model="showModalBankData"
      @refresh="getDefaultBankAccount"
    />
  </div>
</template>

<script>
import ContentLoading from '@components/content-loading';
import DriverBehaviorCard from '@components/drivers/profile/driver-behavior/driver-behavior-card';
import DriverLicenseStatus from '@components/drivers/profile/license-status';
import DriverContractStatus from '@components/drivers/profile/contract-status';
import DriverLockStatus from '@components/drivers/profile/driver-lock-status';
import TotalFinesCard from '@components/drivers/profile/total-fines';
import CardWidget from '@components/shared/card-widget';
import RecurrencyCardWidget from '@components/shared/recurrency-card-widget';
import DriverMileageChart from '@components/drivers/profile/driver-mileage-chart';
import WeeklyTraveled from '@components/drivers/profile/weekly-traveled';

import {
  formatPrice,
  formatNumber,
  formatCpf,
  formatPhone,
  calcDateDiff,
} from '@utils/numbers';
import { BANK_ACCOUNT_TYPE_LIST } from '@enums/bankAccountType';

import DRIVER_TRIPS from '@graphql/driver/queries/get-trips.gql';
import DRIVER_GET from '@graphql/driver/queries/get-profile.gql';
import GET_DEFAULT_BANK_ACCOUNT from '@graphql/payment/queries/getDefaultBankAccount.gql';
import { mapGetters } from 'vuex';

import { makeQuery } from '@graphql/middleware'
import DocumentSendToDriverCard from '@/src/components/drivers/profile/document-send-to-driver.vue';

export default {
  name: 'DriverProfile',
  page: {
    title: 'Motoristas',
  },
  components: {
    TotalFinesCard,
    DriverBehaviorCard,
    ContentLoading,
    DriverLicenseStatus,
    DriverLockStatus,
    CardWidget,
    RecurrencyCardWidget,
    DriverMileageChart,
    WeeklyTraveled,
    DriverContractStatus,
    DocumentSendToDriverCard,
    ModalBankData: () => import('@components/modals/driver-bank-data')
  },
  data() {
    return {
      plan_default: 0,
      fine_limit: 1.9,
      referral_target: 10,
      account: null,
      bankAcountType: BANK_ACCOUNT_TYPE_LIST,
      showModalBankData: false,
    };
  },
  apollo: {
    driver: {
      query: DRIVER_GET,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    driver_trips: {
      query: DRIVER_TRIPS,
      variables() {
        return {
          driver: this.$route.params.id,
        };
      },
      update: data => {
        return data.tripsByDriver;
      },
    }
  },
  computed: {
    ...mapGetters('user', ['groups']),
    recurrency() {
      return (!this.driver.booking || !this.driver.booking.recurrency_data)
        ? {}
        : { ...this.driver.booking.recurrency_data || {} }
    },
  },
  mounted() {
    if (this.$regions.isBrazil()) {
      this.getDefaultBankAccount()
    }
  },
  methods: {
    setNumberFormat(_value) {
      return formatNumber(_value);
    },
    setCpfFormat(_value) {
      return formatCpf(_value);
    },
    setPhoneFormat(_value) {
      return formatPhone(_value);
    },
    getBookingProgress(_booking) {
      return this.$moment(_booking.ended_at).diff(this.$moment(_booking.started_at), 'days');
    },
    getBookingCurrent(_booking) {
      return this.$moment().diff(this.$moment(_booking.started_at), 'days');
    },
    getLicenseStatusColor(_points) {
      if (_points === null) {
        return 'secondary';
      } else if (_points <= 13) {
        return 'success';
      } else if (_points >= 13 && _points <= 21) {
        return 'warning';
      } else {
        return 'danger';
      }
    },
    setPriceFormat(_value) {
      return formatPrice("R$", _value);
    },
    getDateDiff(_start_date, _end_date) {
      return calcDateDiff(_start_date, _end_date);
    },
    getDriverGarageAddress(_driver) {
      if (_driver.garage_other_address) {
        return `${_driver.garage_address.street_name},
                ${_driver.garage_address.street_number},
                ${_driver.garage_address.neighborhood}
                ${_driver.garage_address.postal_code}`;
      }
    },
    getDriverAddress(_driver, _print = false) {
      const hasAddress = _driver.address_street_name ? true : false;
      let address = _driver.address_street_name;
      let __print = _print ? ', ' : '<br>';
      address += _driver.address_street_no_number ? ', N/A' : ', ' + _driver.address_street_number;
      address +=
        (_driver.address_street_details ? ', ' + _driver.address_street_details : '') + __print;
      address +=
        _driver.address_neighborhood || _driver.address_city || _driver.address_state ?
          (_driver.address_neighborhood ? _driver.address_neighborhood : '') +
          (_driver.address_city ? ', ' + _driver.address_city : '') +
          (_driver.address_state ? ', ' + _driver.address_state : '') +
          __print :
          '';
      address += _driver.address_postal_code ? _driver.address_postal_code + __print : '';
      address += _print ? '' : _driver.address_country ? _driver.address_country : '';

      if (_print) {
        return hasAddress ? encodeURI(address) : false;
      } else {
        return hasAddress ? address : false;
      }
    },
    getDefaultBankAccount() {
      // this.loading = true
      makeQuery(
        GET_DEFAULT_BANK_ACCOUNT,
        {
          driver: this.$route.params.id,
        },
      )
      .then((res) => {
        this.account = res.data.getDefaultBankAccount
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
    },
    setTypeAccount(type) {
      const accountType = this.bankAcountType.find((account) => account.value === type)
      return accountType.text
    },
    showModal() {
      this.showModalBankData = true
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-data {
  .card-title {
    font-size: 20px;
  }

  p {
    margin-bottom: 10px;

    &.cta-data {
      margin-top: 20px;
    }

    &.card-address {
      line-height: 24px;
      margin-top: -4px;
    }

    &.card-address-search {
      > .btn {
        color: #fff !important;
      }
    }

    .detail {
      color: #666;
      font-size: 10px;
      margin-left: 5px;
    }
  }
}
</style>
